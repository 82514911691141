import React, { useState, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';

const CLIENT_API_KEY_NAME = "api_key";
const CUSTOMER_API_KEY_NAME = "customer_api_key";
const CLIENT_API_KEY = "API1qaz2wsx";
const CUSTOMER_API_KEY = "API3ed4";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_FRONTEND_KEY);

const SubscriptionPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [code, setCode] = useState(new Array(6).fill(''));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [step, setStep] = useState(1); // 1 for email, 2 for verification code
    const [focusedIndexes, setFocusedIndexes] = useState(new Array(6).fill(false));
    const [accountExists, setAccountExists] = useState(false);
    const [plan, setPlan] = useState('monthly');

    const inputsRef = useRef([]);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setError('Invalid Email');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/send-verification-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'customer-api-key': CUSTOMER_API_KEY,
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to send verification code.');
            }

            setStep(2);
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to send verification code.');
        } finally {
            setLoading(false);
        }
    };
    const handleCodeChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value && index < 5) {
                inputsRef.current[index + 1].focus();
            } else if (!value && index > 0) {
                inputsRef.current[index - 1].focus();
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text').slice(0, 6).split('');
        const newCode = [...code];

        pasteData.forEach((value, index) => {
            newCode[index] = value;
            if (inputsRef.current[index]) {
                inputsRef.current[index].value = value;
            }
        });

        setCode(newCode);
    };

    const handleFocus = (index) => {
        const newFocusedIndexes = [...focusedIndexes];
        newFocusedIndexes[index] = true;
        setFocusedIndexes(newFocusedIndexes);
    };


    const handleCodeSubmit = async (e) => {
        e.preventDefault();
    
        const fullCode = code.join('');
        if (fullCode.length !== 6) {
            setError('Please enter the full 6-digit code.');
            return;
        }
    
        setLoading(true);
        setError(null);
    
        try {
            const verifyResponse = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/verify-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'customer-api-key': CUSTOMER_API_KEY,
                },
                body: JSON.stringify({ email, code: fullCode }),
            });
    
            if (!verifyResponse.ok) {
                throw new Error('Verification failed.');
            }
    
            const customerCheckResponse = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/check-customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'customer-api-key': CUSTOMER_API_KEY,
                },
                body: JSON.stringify({ email }),
            });
    
            const customerCheckData = await customerCheckResponse.json();
    
            if (customerCheckData.exists) {
                // Store verification state in localStorage with timestamp
                const verificationData = {
                    email,
                    timestamp: Date.now(),
                    verified: true
                };
                try {
                    // Clear any existing verification first
                    localStorage.removeItem('emailVerification');
                    // Set new verification
                    localStorage.setItem('emailVerification', JSON.stringify(verificationData));
                    console.log('Stored verification:', localStorage.getItem('emailVerification'));
                    
                    navigate(`/account?email=${encodeURIComponent(email)}`);
                } catch (storageError) {
                    console.error('Storage error:', storageError);
                }
            } else {
                await createCheckoutSession();
            }
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to verify code.');
        } finally {
            setLoading(false);
        }
    };
    
    

    const createCheckoutSession = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_NODE_BACKEND_HOST}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'customer-api-key': CUSTOMER_API_KEY,
                },
                body: JSON.stringify({ email, plan }), // Pass the selected plan to the backend
            });
    
            const session = await response.json();
    
            // Log the full response to debug if session.id is not found
            //console.log('Checkout Session Response:', session);
    
            if (!session.id) {
                throw new Error('Session ID not found.');
            }
    
            const stripe = await stripePromise;
    
            // Redirect to Stripe checkout with session ID
            stripe.redirectToCheckout({ sessionId: session.id });
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to create checkout session.');
        }
    };
    
    

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };


    const styles = {
        container: {
            margin: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Inter, sans-serif',
        },
        contentBox: {
            padding: '30px 48px',
            backgroundColor: 'transparent',
            color: 'white',
            maxWidth: '500px',
        },
        title: {
            marginTop: '30px',
            fontSize: '28px',
        },
        subtext: {
            marginTop: '30px',
            lineHeight: '1.6',
            fontSize: '16px',
            color: '#a8b3c2ff',
        },
        link: {
            marginTop: '20px',
            marginBottom: '15px',
            fontSize: '16px',
            color: '#336fe4ff',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        input: {
            width: '100%',
            marginTop: '30px',
            padding: '12px',
            fontSize: '16px',
            border: '1px solid #4e5a68ff',
            backgroundColor: '#242b33ff',
            color: 'white',
            borderRadius: '4px',
            boxSizing: 'border-box',
            outline: 'none',
            transition: 'border-color 0.3s ease',
        },
        inputBox: {
            width: '50px',
            height: '70px', // Make the box taller
            margin: '10px',
            padding: '12px',
            fontSize: '24px',
            textAlign: 'center',
            border: '1px solid #4e5a68ff',
            backgroundColor: '#242b33ff',
            color: 'white',
            borderRadius: '4px',
            outline: 'none',
            transition: 'border-color 0.3s ease',
        },
        inputBoxFilled: {
            borderColor: '#3769e4ff', // Blue color when filled
        },
        button: {
            width: '100%',
            marginTop: '20px',
            padding: '12px',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#3769e4ff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        logoImage: {
            width: '250px',
            height: 'auto',
        },
        error: {
            color: 'red',
            marginTop: '10px',
            fontSize: '14px',
        },
        codeInputContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.contentBox}>
                {step === 1 && (
                    <>
                    <img src={`${process.env.PUBLIC_URL}/logo-generate-reverse.png`} alt="Logo" style={styles.logoImage} />
                        <h1 style={styles.title}>Claim your 30 Day Free Trial!</h1>
                        <p style={styles.subtext}>
                            Experience the power of AI running privately on top of your AI PC. <br />
                            Search and ask questions to your private documents and integrations in a private, offline, and secure environment. <br />
                            No credit card required. Cancel anytime or subscribe for $9.99 a month or $100 annually.
                        </p>
                        <a
                            href="https://www.iterate.ai/solutions/Generate_Application"
                            style={{
                                marginTop: '20px',
                                marginBottom: '15px',
                                fontSize: '16px',
                                color: '#336fe4ff',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                fontFamily: 'Inter, sans-serif',
                                display: 'block',
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn more about Generate
                        </a>
                        <form onSubmit={handleEmailSubmit}>
                            <input
                                type="text"
                                style={styles.input}
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {error && <p style={styles.error}>{error}</p>}
                            <button
                                style={styles.button}
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Send Verification Code'}
                            </button>
                        </form>
                    </>
                )}
    
    {step === 2 && (
    <>
        <h1 style={styles.title}>Enter Your Verification Code</h1>
        <p style={styles.subtext}>
            We have sent a 6-digit verification code to your email. Please enter it below to verify your email and continue with redeeming your free trial. 
        </p>
        <form onSubmit={handleCodeSubmit}>
            <div style={styles.codeInputContainer}>
                {code.map((digit, index) => (
                    <input
                        key={index}
                        ref={(el) => (inputsRef.current[index] = el)}
                        type="text"
                        style={{
                            ...styles.inputBox,
                            ...(digit ? styles.inputBoxFilled : {}),
                        }}
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleCodeChange(index, e.target.value)}
                        onPaste={handlePaste}
                    />
                ))}
            </div>
            {accountExists && (
                <p style={{ fontSize: '14px', color: '#3769e4ff' }}>
                Account already exists. <a href="/account" style={{ textDecoration: 'underline', color: '#3769e4ff' }}>Login here</a>.
                </p>
            )}

            {error && <p style={styles.error}>{error}</p>}
            <button
                style={styles.button}
                type="submit"
                disabled={loading}
            >
                {loading ? 'Verifying...' : 'Verify and Continue'}
            </button>
        </form>
    </>
)}

            </div>
        </div>
    );
    
};

export default SubscriptionPage;
