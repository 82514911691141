import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SubscriptionPage from './SubscriptionPage';
import SuccessPage from './SuccessPage';
import LoginPage from './Account';
import EulaPage from './EulaPage';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    {/* Home route displaying the trial form and preview image */}
                    <Route path="/" element={
                        <>
                            <div className="left-side">
                                <SubscriptionPage />
                            </div>
                            <div className="right-side">
                                <img 
                                    src={`${process.env.PUBLIC_URL}/generate-preview.png`} 
                                    alt="Preview" 
                                    className="preview-image" 
                                />
                            </div>
                        </>
                    } />
                    
                    
                    {/* Route for subscription page */}
                    <Route path="/subscribe" element={
                        <>
                            <div className="left-side">
                                <SubscriptionPage />
                            </div>
                            <div className="right-side">
                                <img 
                                    src={`${process.env.PUBLIC_URL}/generate-preview.png`} 
                                    alt="Preview" 
                                    className="preview-image" 
                                />
                            </div>
                        </>
                    } />
                    
                    {/* Route for success page */}
                    <Route path="/success" element={
                        <>
                            <div className="left-side">
                                <SuccessPage />
                            </div>
                            <div className="right-side">
                                <img 
                                    src={`${process.env.PUBLIC_URL}/generate-preview.png`} 
                                    alt="Preview" 
                                    className="preview-image" 
                                />
                            </div>
                        </>
                    } />

                    {/* Route for account/login page */}
                    <Route path="/account" element={
                        <>
                            <div className="left-side">
                                <LoginPage />
                            </div>
                            <div className="right-side">
                                <img 
                                    src={`${process.env.PUBLIC_URL}/generate-preview.png`} 
                                    alt="Preview" 
                                    className="preview-image" 
                                />
                            </div>
                        </>
                    } />

                    {/* Route for eula page */}
                    <Route path="/eula" element={
                        <>
                            <div className="left-side">
                                <EulaPage />
                            </div>
                            <div className="right-side">
                                <img 
                                    src={`${process.env.PUBLIC_URL}/generate-preview.png`} 
                                    alt="Preview" 
                                    className="preview-image" 
                                />
                            </div>
                        </>
                    } />


                </Routes>

                <footer className="App-footer">
                    <div className="footer-links">
                        <a href="https://www.iterate.ai/terms-of-use" className="footer-link" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                        <a href="https://www.iterate.ai/privacy-policy" className="footer-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        <a href="https://generatehelp.iterate.ai/eula" className="footer-link" target="_blank" rel="noopener noreferrer">EULA</a>

                    </div>
                </footer>
            </div>
        </Router>
    );
}

export default App;
